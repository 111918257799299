import React from "react"
import styled from "styled-components"

import Label from "../../elements/label"
import Slider from "../../elements/Slider"
// import BuyLink from "../../elements/BuyLink"

import spotify from "./spotify.png"
import apple from "./apple.png"

import content from "./content"

import media from "../../../styles/media"

const Container = styled.div`
  position: relative;
  background-color: rgba(63, 63, 63, 0.99);
  padding: 5vh 0 5vh 0;
  ${media.tablet`
    padding: 10vh 0 10vh 0;
  `}
`

const Header = styled.div`
  padding-left: 10vw;
  padding-right: var(--gutter-s);
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  /* align-items: center; */
  justify-content: center;
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .channels {
    display: flex;
    align-items: center;
    span {
      color: rgba(255, 255, 255, 0.95);
      text-transform: uppercase;
      font-family: "AvenirLTStdBlack";
      font-size: 0.75rem;
      padding-right: 15px;
      padding-top: 3px;
    }
    a {
      display: flex;
      img {
        height: 25px;
        width: auto;
        &.appleLogo {
          max-height: 17px;
        }
      }
      &.spotify {
        padding-right: 15px;
      }
    }
  }
  ${media.tablet`
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    padding-left: 25vw;
    padding-right: var(--gutter-l);
    justify-content: space-between;
    margin-bottom: 4rem;
    h2 {
      font-size: 2rem;
      margin-right: 4rem;
      margin-bottom: 0;
    }
    .channels {
      a {
        img {
          height: 30px;
          &.appleLogo {
            max-height: 20px;
          }
        }
      }
    }
  `}
`

const SliderContainer = styled.div`
  margin-bottom: 2rem;
  .swiper-container {
    padding-left: 10vw;
  }
  ${media.tablet`
    margin-bottom: 4rem;
    .swiper-container {
      padding-left: 25vw;
    }

  `}
`

const Meta = styled.div`
  padding-left: 10vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: "title" "social-links" "buy-link";
  grid-gap: 1rem 0;
  h2 {
    grid-area: title;
    font-size: 1.5rem;
  }
  .social-links {
    grid-area: social-links;
  }
  .buy-link {
    grid-area: buy-link;
  }
  ${media.tablet`
    padding-left: 25vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "title ." "social-links buy-link";
    h2 {
      font-size: 2rem;
    }
    .social-links {
      align-self: center;
    }
    .buy-link {
      justify-self: flex-end;
      align-self: center;
      padding-right: var(--gutter-l);
    }
  `}
`

const Bibliography = ({ ...props }) => {
  return (
    <Container {...props}>
      <Header>
        <h2>Discography</h2>
        <div className="channels">
          <span>Listen on</span>
          <a
            href="https://open.spotify.com/artist/6wMr4zKPrrR0UVz08WtUWc"
            target="_blank"
            rel="noreferrer noopener"
            className="spotify mouse-link"
          >
            <img src={spotify} alt="Sound Idea Sessions | Spotify" />
          </a>
          <a
            href="https://music.apple.com/za/artist/black-coffee/306140760"
            target="_blank"
            rel="noreferrer noopener"
            className="mouse-link"
          >
            <img
              className="appleLogo"
              src={apple}
              alt="Sound Idea Sessions | Apple"
            />
          </a>
        </div>
      </Header>
      <SliderContainer>
        <Slider content={content} />
      </SliderContainer>
      <Meta>
        <h2>Social</h2>
        <div className="social-links">
          <Label
            fontSize={0.8}
            fontScaled
            mRight={1}
            outerRotate={0}
            innerRotate={1}
            value="Instagram"
            url="https://www.instagram.com/realblackcoffee"
            className="mouse-link"
          />
          <Label
            fontSize={0.8}
            fontScaled
            mRight={1}
            outerRotate={0}
            innerRotate={1}
            value="Twitter"
            url="https://twitter.com/RealBlackCoffee"
            className="mouse-link"
          />
          <Label
            fontSize={0.8}
            fontScaled
            mRight={1}
            scaleText
            outerRotate={0}
            innerRotate={1}
            value="Facebook"
            url="https://www.facebook.com/realblackcoffee"
            className="mouse-link"
          />
        </div>
        {/* <div className="buy-link">
          <BuyLink />
        </div> */}
      </Meta>
    </Container>
  )
}

export default Bibliography
