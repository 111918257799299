import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import BlackCoffeePage from "../components/BlackCoffeePage"

const BlackCoffee = () => {
  const menuItems = [
    { name: "biography", scrollLink: true },
    { name: "discography", scrollLink: true },
  ]
  return (
    <>
      <SEO title="Black Coffee" />
      <Navbar back items={menuItems} topOffset={0} bottomOffset={125} />
      <BlackCoffeePage />
      <Footer back items={menuItems} />
    </>
  )
}

export default BlackCoffee
