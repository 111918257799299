import React from "react"

import Biography from "./Biography"
import Discography from "./Discography"

const RyanHolidayPage = () => {
  return (
    <>
      <Biography name="biography" />
      <Discography name="discography" />
    </>
  )
}

export default RyanHolidayPage
