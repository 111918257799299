const content = [
  {
    year: "2018",
    link_name: "Buy this album",
    link_url:
      "https://open.spotify.com/album/7FbVyiO5m2irjYB6t5JYCH?si=PBaFZ4roRoO7JyKQOpexkA",
    image: "BlackCoffeePage/albums/bc-album-6.jpg",
  },
  {
    year: "2015",
    link_name: "Buy this album",
    link_url:
      "https://open.spotify.com/album/1QLCBRU36FaUQtvKRofd03?si=jzdj5iTHSLqJr3sSjg-uWA",
    image: "BlackCoffeePage/albums/bc-album-5.jpg",
  },
  {
    year: "2012",
    link_name: "Buy this album",
    link_url:
      "https://open.spotify.com/album/1GdL8XkbVTTVQPORBzmcMp?si=qLZOYPmgQeicR1PWbp7ZRw",
    image: "BlackCoffeePage/albums/bc-album-4.jpg",
  },
  {
    year: "2009",
    link_name: "Buy this album",
    link_url:
      "https://open.spotify.com/album/4z9UkNYW3T0EZzcGNjBsSz?si=QYk0HLLOSm-pYvoM4u6EJw",
    image: "BlackCoffeePage/albums/bc-album-3.jpg",
  },
  {
    year: "2007",
    link_name: "Buy this album",
    link_url:
      "https://open.spotify.com/album/5sMK7vkPd3CZF0fTny80gj?si=-NmDXISsRWGOwUlgPc2shw",
    image: "BlackCoffeePage/albums/bc-album-2.jpg",
  },
  {
    year: "2005",
    link_name: "Buy this album",
    link_url:
      "https://open.spotify.com/album/7FaEOKzc0JA0aZiK6Y7mNb?si=xrhVg0dvSiSKdSvfFMU0Bg",
    image: "BlackCoffeePage/albums/bc-album-1.jpg",
  },
]

export default content
