import React from "react"
import styled from "styled-components"

const Container = styled.div`
  transform: rotate(-1deg);
  svg {
    enable-background: new 0 0 1062.8 310.3;
  }
`

const Name = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 1062.8 310.3">
        <g>
          <path
            d="M4.8,155.8V13.6h142.7c33.7,0,52.3,12.6,52.3,35.6c0,14.6-8.1,25.3-24.4,32.3v1.1c6.6,0.4,12,1.7,16.5,4.1
		c11.1,5.8,16.7,15.9,16.7,30.8c0,25.5-17.8,38.3-53.3,38.3H4.8z M120.1,54.9c0-4.5-1.7-8.1-4.9-10.9c-3-2.4-6.6-3.6-10.9-3.6H85
		v28.9h19.3C113.5,69.3,120.1,64,120.1,54.9z M126.3,112.1c0-5.4-1.9-9.9-5.8-13.1c-3.6-2.8-7.9-4.3-12.9-4.3H85v34.5h22.5
		C118.6,129.1,126.3,123.3,126.3,112.1z"
          />
          <path d="M296.3,9.3v108.1h72.8v37.5H214.3V9.3H296.3z" />
          <path d="M466.7,133.2l-6.9,20h-80.4L431.2,7.5h128.6l51.5,145.7h-80.2l-7.1-20H466.7z M512.7,101l-17.3-49.2L478.1,101H512.7z" />
          <path
            d="M830,60.4h-83.8c0-8.3-1-15.2-2.7-20.8c-1-3.3-3.1-6-6.3-8.5c-3.6-2.9-8.1-4.4-12.9-4.4c-9.8,0-16.5,3.3-19.8,9.8
		c-2.9,5.6-4.2,19-4.2,40c0,19.8,1.3,32.9,4.2,39.2c3.5,7.7,10,11.5,19.8,11.5c9.8,0,16.2-4.2,19.2-12.7c1.7-4.4,2.5-11.5,2.5-20.9
		h84c-0.6,16.3-3.6,28.6-9,36.7c-6.9,10.4-19.2,17.1-36.7,20.2c-12.5,2.1-32.7,3.3-60.2,3.3s-47.5-1.2-60-3.3
		c-20.2-3.7-33.6-12.3-40-26.1c-4-9-6-24.8-6-47.5c0-22.7,1.9-38.4,6-47.5c6.3-13.8,19.8-22.5,40-26.1c12.5-2.1,32.5-3.3,60-3.3
		c27.9,0,47.9,1.2,60.2,3.3c17.5,3.1,29.8,9.8,36.7,20.4C826.3,31.9,829.4,44,830,60.4z"
          />
          <path d="M921,91.1v57h-81.4V3.5H921v56.8l37.6-56.8h90.8l-45.8,65.8l58.2,78.8H963L921,91.1z" />
          <path
            d="M200,222.2h-79.1c0-7.8-0.9-14.3-2.5-19.6c-0.9-3.1-2.9-5.6-6-8c-3.4-2.7-7.6-4.2-12.2-4.2c-9.2,0-15.6,3.1-18.7,9.3
		c-2.7,5.3-4,18-4,37.7c0,18.7,1.3,31,4,37c3.3,7.3,9.4,10.9,18.7,10.9c9.3,0,15.2-4,18.1-12c1.6-4.2,2.4-10.9,2.4-19.8H200
		c-0.5,15.4-3.4,27-8.5,34.7c-6.5,9.8-18.1,16.2-34.7,19.1c-11.8,2-30.9,3.1-56.8,3.1s-44.8-1.1-56.6-3.1
		c-19-3.5-31.8-11.6-37.7-24.7C1.8,274.1,0,259.2,0,237.8c0-21.4,1.8-36.3,5.6-44.8c6-13.1,18.7-21.2,37.7-24.7
		c11.8-2,30.7-3.1,56.6-3.1c26.3,0,45.2,1.1,56.8,3.1c16.5,2.9,28.1,9.3,34.7,19.2C196.5,195.3,199.4,206.7,200,222.2z"
          />
          <path
            d="M310.5,162.2c26.3,0,45.4,1.1,57.3,3.1c19.3,3.5,32.2,11.8,38.2,25c3.9,8.6,5.7,23.7,5.7,45.4c0,21.7-1.8,36.8-5.7,45.4
		c-6.1,13.2-18.9,21.5-38.2,25c-11.9,2-31.1,3.1-57.3,3.1c-26.5,0-45.6-1.1-57.5-3.1c-19.3-3.5-32.2-11.8-38.2-25
		c-3.9-8.6-5.7-23.7-5.7-45.4c0-21.7,1.8-36.8,5.7-45.4c6.1-13.2,18.9-21.5,38.2-25C264.9,163.3,284,162.2,310.5,162.2z
		 M310.5,283.8c9.4,0,15.6-3.7,18.9-11c2.8-6.1,4.1-18.6,4.1-37.5c0-20-1.3-32.9-4.1-38.2c-3.1-6.2-9.5-9.4-18.9-9.4
		c-9.4,0-15.6,3.1-18.9,9.4c-2.6,5-3.8,17.6-3.8,38.2c0,19.3,1.3,31.8,3.8,37.5C294.9,280.2,301.1,283.8,310.5,283.8z"
          />
          <path
            d="M499.9,219.2c0,0.2,24.1,0.2,72.3,0v28.1c-48.2,0.2-72.3,0.2-72.3,0v55.3h-77.4V165.2h149.7v35.3
		c-48.2-0.4-72.3-0.4-72.3,0V219.2z"
          />
          <path
            d="M661.2,216.2c0,0.2,24.1,0.2,72.3,0v28.1c-48.2,0.2-72.3,0.2-72.3,0v55.3h-77.4V162.2h149.7v35.3
		c-48.2-0.4-72.3-0.4-72.3,0V216.2z"
          />
          <path
            d="M821.8,215.2c0,0.2,24.8,0.2,74.5,0v28.1c-49.6,0.2-74.5,0.2-74.5,0v19.9h74.5v35.3H744.4V161.2h151.8v35.3
		c-49.6-0.4-74.5-0.4-74.5,0V215.2z"
          />
          <path
            d="M988.3,213.2c0,0.2,24.8,0.2,74.5,0v28.1c-49.6,0.2-74.5,0.2-74.5,0v19.9h74.5v35.3H911V159.2h151.8v35.3
		c-49.6-0.4-74.5-0.4-74.5,0V213.2z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Name
