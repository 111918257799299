import React from "react"
import styled from "styled-components"

import Image from "../../elements/image"

import Name from "./name"

import media from "../../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: auto auto;
  grid-template-areas: "name name" "photo story";
  overflow: hidden;
`

const NameContainer = styled.div`
  position: relative;
  grid-area: name;
  overflow: hidden;
  padding-bottom: 4rem;
  ${media.tablet`
    padding-bottom: 6rem;
  `}
`

const Photo = styled.div`
  grid-area: photo;
  position: relative;
  transform: scale(1.5);
  transform-origin: top left;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 93, 107, 0.99);
    mix-blend-mode: lighten;
  }
`

const Story = styled.div`
  position: relative;
  grid-area: story;
  padding-bottom: 4rem;
  h4 {
    padding: 0 5vw 0 0;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  p {
    padding: 0 10vw 0 0;
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  ${media.tablet`
    padding-bottom: 6rem;
    h4 {
      padding: 0 5vw 0 0;
      font-size: 1.85rem;
    }
    p {
      padding: 0 20vw 0 0;
      font-size: 1.25rem;
      line-height: 1.75;
    }
  `}
`

const Biography = ({ ...props }) => {
  return (
    <Container {...props}>
      <Photo>
        <Image
          src="BlackCoffeePage/black_coffee.png"
          alt="Sound Idea Sessions | Black Coffee"
        />
      </Photo>
      <NameContainer name="biography">
        <Name />
      </NameContainer>
      <Story>
        <h4>
          Black Coffee is, humbly put, a South African DJ and music producer.
          But his talent, countless accomplishments and whirlwind rise to fame
          make this description wholly inadequate.
        </h4>
        <p>
          These qualities, a dedicated work ethic and back to back worldwide
          tours are what have made him into arguably one of the most prominent
          producers out of the African continent. Known for his penchant for
          evocative Afro-house, his true talent lies in his holistic mission to
          rewrite musical conventions of that genre. Afrocentric yet globally
          contemporary Black coffee defies musical clichés and modernises
          African music through harmonious collaborations and crisp production.
          Described as a ‘reserved intellectual’ Nkosinathi Innocent Maphumulo -
          his birth name, had always had aspirations of becoming a DJ even from
          the tender of age of 14. The year 1990 was a pivotal time in his life
          and for South Africa, it is the same year apartheid struggle icon
          Nelson Mandela was released from Robben Island and, ironically, on the
          same day of his release, Maphumulo lost the use of his left arm
          because of a violent political attack that lead to a car accident.
        </p>
        <p>
          Influenced by the musical nature of a religious household and growing
          up in Mthatha, Eastern Cape and Durban, KwaZulu-Natal; he’d later go
          on to explore music’s nuances. He slowly acquainted himself with
          electronic music and playing at homely events with his cousin as a
          teenager. After completing high school, he went on to study music as a
          Jazz major student at Natal Technikon, now known as Durban University
          of Technology. Every great artist has one definitive moment where
          their music or talent is recognized, and for Black Coffee this came in
          2004 after being chosen to participate in the Red Bull Music Academy
          held in Cape Town that year. The following year Black Coffee founded
          his record label Soulistic Music and released his debut self-titled
          album which featured a remixed version of South African classic
          Stimela by the legendary Hugh Masekela. The album itself was
          impressive for being created using simple music- making software.
        </p>
        <p>
          It introduced his particular style of house music that wove Jazz, R&B
          and African influences to create something that was fresh and
          instinctually masterful. It also featured collaborations with the
          likes of acclaimed South African musicians including Busi Mhlongo,
          Thandiswa Mazwai and Hugh Masekela. In 2007, Black coffee continued to
          see his star rise as a well-known South African DJ and producer in the
          house music fraternity. The year saw him release his second studio
          album titled Have Another One which continued to solidify his prowess
          in creating tribally laced beats with an infectious flair. An invite
          came from the Red Bull Music Academy to play at the Sonar Festival in
          Barcelona, this opportunity gave Black Coffee a platform to showcase
          his talent to an international audience whilst holding his own against
          highly-ranked local and international DJs. In 2009 he released his
          multi-award-winning album Home Brewed, this album featured hit tracks
          such as Juju, We Are One featuring Hugh Masekela, Trip To Lyon and
          Superman which was later sampled in 2017 by Drake as Get It Together
          in the album More Life. The Home Brewed album could easily be labelled
          the project that made his musical affinity undeniable. In the year
          2010 Black Coffee continued to conquer his dreams when he performed
          non-stop for 60 hours in Soweto, Johannesburg. In 2011 he took to new
          heights with his fourth album Africa Rising when he performed a live
          act with a 24-piece orchestra at the Moses Mabhida Stadium in his
          birth city, Durban.
        </p>
        <p>
          This was followed by the presentation of Africa Rising as a triple-
          disc album including a DVD that sold double platinum. 2015 was a
          phenomenal year for Black Coffee as he toured internationally and
          released the international platinum-selling and multi- award-winning
          album Pieces Of Me which featured the double platinum single We Dance
          Again. In the same year he was awarded his first global award as
          Breakthrough DJ of the Year by DJ Awards in Ibiza, Spain. Black Coffee
          conquered the year 2016 with a release of an EP titled Journey
          Continues. This was followed by a song feature in a surprise release
          of a remix EP by the Grammy award-winning singer & songwriter Alicia
          Keys’ song In Common. He was then awarded Best Deep House DJ by DJ
          Awards in Ibiza, Spain and Best International Act: Africa by BET
          Awards in Los Angeles, USA. 2017 Black Coffee blew up as when he
          confirmed a summer residency at Hï Ibiza a club formerly known as
          Space in Ibiza and toured Europe right through the summer. A tour for
          him included and continues to grow to performing at the world’s
          biggest international festivals and best nightclubs such as Coachella
          Music & Arts Festival, Ultra Music Festivals, Tomorrowland, Sonar,
          EXIT Festival, Awakenings Festival, Pacha, DC-10, Output, Watergate
          and Shimmy Beach just to mention a few. Black Coffee’s efforts to
          redefine and promote South African music and bring to it a greater
          audience makes him more than just a DJ. They make him a burgeoning
          legend and artist whose star continues to shoot across cultures and
          generations of musicians not only within South Africa but across the
          world.
        </p>
      </Story>
    </Container>
  )
}

export default Biography
